// -----------------
// STATE - This defines the type of data maintained in the Redux store.

import { Vehicle } from "./Vehicle/vehicle";
import { v4 as uuid } from 'uuid';

export enum LineOfBusinessType {
  NotSet = '',
  Homeowners = 'HO',
  PersonalAuto = 'PA',
  Package = 'PP'
}

export enum homeownersType {
  Homeowner = '1',
  Renters = '2',
  CondoOwner = '3',
  None = ''
}

export enum gender {
  Male = 'Male',
  Female = 'Female',
  NonSpecified = 'Non-Specified',
  None = ''
}

export enum contactMethod {
  Email = 'Email',
  Phone = 'Phone',
  Text = 'Text',
  None = ''
}

export enum contactTime {
  Morning = 'Morning',
  Afternoon = 'Afternoon',
  Evening = 'Evening',
  None = ''
}

export enum coveragePlan {
  StateMin = '1',
  Basic = '2',
  Standard = '3',
  Preferred = '4',
  None = ''
}

export enum RateStatus {
  NeedsRated = 0,
  Rated = 1,
  InProgress = 2
}

export enum IncidentType {
  None = '',
  Accident = '1',
  Violation = '2',
  ComprehensiveLoss = '3'
}

export enum AccidentType {
  None = '',
  AtFaultWithInjury = '1',
  AtFaultWithNoInjury = '2',
  NotAtFault = '3'
}

export class Incident {
  [x: string]: any;
  incidentType: IncidentType = IncidentType.None;
  incidentDate: string = '';
  accidentType: AccidentType = AccidentType.None;
  bodilyInjuryAmount: number = 0;
  propertyDamageAmount: number = 0;
  violationType: string = '';
  actualSpeed: number | null = null;
  postedLimit: number | null = null;
  lossType: string = '';
  lossAmount: number | null = null;
  deathOccurred: boolean | null = null;
}

export enum HomeLossType {
  None = '',
  Fire = '1',
  Theft = '2',
  Flood = '3',
  Other = '4'
}

export class HomeLoss {
  lossCause: HomeLossType = HomeLossType.None;
  lossDate: string = '';
  lossAmountPaid: number | null = null;
}

export class Driver {
  id: string = '';

  firstName: string = '';
  middleInitial: string = '';
  lastName: string = '';
  email: string = '';
  phone: string = '';
  preferredContact: contactMethod = contactMethod.None;
  bestTimeToContact: contactTime = contactTime.None; 
  relationship: string = '';
  dateOfBirth: string = '';

  gender: gender = gender.None;
  maritalStatus: string = '';
  ssn: string = '';
  driversLicenseNum: string = '';
  employmentIndustry: string = '';
  occupation: string = '';

  hasIncidents: boolean | null = null;
  incidents: Incident[] = [];

  lead: string = '';
}

export class Coverages {
  priorCarrier: string = '';
  packagePriorCarriersDifferent: boolean | null = null;
  curCovLevel: string = '';
  curPolicyExpDate: string = '';
  curEstPrem: number | null = null;
  policyStartDate: string = '';
  bundleAutoHome: boolean | null = null;
  paperlessBilling: boolean = true;
  smokeDetector: boolean = false;
  fireExtinguisher: boolean = false;
  burglarAlarm: boolean = false;
  deadBoltLocks: boolean = false;
  smokersInHousehold: boolean | null = null;
  compDed: string = '';
  collDed: string = '';
  homeDed: string = '';
  homeCoverage: number | null = null;
  selectedPlan: coveragePlan = coveragePlan.None;
}

export class HomeCharacteristics {
  residenceType: string = '';
  yearsAtResidence: number | null = null;
  yearBuilt: number = 0;
  squareFootage: number = 0;
  construction: string = '';
  milesToCoast: number | null = null;
  feetToCoast: number | null = null;
  numberOfUnits: number = 0;

  renovated: boolean | null = null;
  hasHomeLosses: boolean | null = null;

  roofUpdateYear: number | null = null;
  plumbingUpdateYear: number | null = null;
  electricalUpdateYear: number | null = null;
  heatingUpdateYear: number | null = null; 

  homeLosses: HomeLoss[] = [];
}

export class QuoteState {
  isPersonalAuto: boolean = false;
  homeowners: homeownersType = homeownersType.None;
  lineOfBusiness: LineOfBusinessType = LineOfBusinessType.NotSet;

  fullAddress: string = '';
  addressState: string = '';
  addressLine1: string = '';
  addressLine2: string = '';
  city: string = '';
  zip: string = '';
  addressVerified: boolean = false;

  drivers: Driver[] = [];
  vehicles: Vehicle[] = [];
  homeCharacteristics: HomeCharacteristics | null = null;
  coverages: Coverages = new Coverages();

  correlationId: string | null = null;
  rateStatus: RateStatus = RateStatus.NeedsRated;
  quoteId: string = uuid();
  hasBeenRated: boolean = false;

  ref1: string = '';
  ref2: string = '';
  ref3: string = '';
  ref4: string = '';
  ref5: string = '';

  agencyLicense: string = '';
}

export const initialState: QuoteState = new QuoteState();
